





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { changeEmailReqest } from '@/store/user/api';

@Component
export default class ChangeEmail extends Vue {
  // Data
  private message: string = '';

  // Vue Life cycle hooks
  async created() {
    const { token } = this.$route.params;
    await changeEmailReqest(token)
      .then(({ status }) => {
        if (status === 200) {
          this.message = String(this.$t('email_changed'));
        }
      })
      .catch(() => {
        this.message = String(this.$t('failed_change_email'));
      });
  }
}
